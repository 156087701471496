.history_line
  .additional-content
    @extend .row
    .content_block
      @extend .col-auto

  @media (min-width: $xs)
    .content_block
      margin: 0 auto
      .content_block_image
        img
          height: 150px
          width: 150px
      .content-block-text
        margin-top: 1em
        max-width: 150px
        text-align: center
        h3
          font-size: 20px

  @media (min-width: $lg)
    .content_block
      margin: 0
      &:not(:last-child)
        margin-right: auto
      .content-block-text
        h3
          font-size: 25px
