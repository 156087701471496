.webp
  .bg-meeting-room
    background: url('../images/backgrounds/webp/besprechungsraum.webp') no-repeat (center / cover)

  .bg-meeting-room-2
    background: url('../images/backgrounds/webp/meeting_room.webp') no-repeat (center / cover)

  .bg-meeting-room-detail
    background: url('../images/backgrounds/webp/meeting_room_detail.webp') no-repeat (center / cover)

  .bg-augsburg
    background: url('../images/backgrounds/webp/augsburg.webp') no-repeat (center / cover)

  .bg-whiteboard
    background: url('../images/backgrounds/webp/whiteboard.webp') no-repeat (center / cover)

  .bg-postits
    background: url('../images/backgrounds/webp/postits.webp') no-repeat (center / cover)

  .bg-kanban
    background: url('../images/backgrounds/webp/kanban.webp') no-repeat (center / cover)

  .bg-workplace-1
    background: url('../images/backgrounds/webp/workplace_1.webp') no-repeat (center / cover)

  .bg-workplace-2
    background: url('../images/backgrounds/webp/workplace_2.webp') no-repeat (center / cover)

  .bg-happy-people
    background: url('../images/backgrounds/webp/happy_people.webp') no-repeat (center / cover)

  .bg-face-to-face
    background: url('../images/backgrounds/webp/face_to_face.webp') no-repeat (center / cover)

  .bg-files
    background: url('../images/backgrounds/webp/files.webp') no-repeat (center / cover)

  .bg-server
    background: url('../images/backgrounds/webp/server.webp') no-repeat (center / cover)

  .bg-people-phones
    background: url('../images/backgrounds/webp/people_phones.webp') no-repeat (center / cover)

  .bg-router
    background: url('../images/backgrounds/webp/router.webp') no-repeat (center / cover)

  .bg-access-point
    background: url('../images/backgrounds/webp/access_point.webp') no-repeat (center / cover)

  .bg-texting
    background: url('../images/backgrounds/webp/texting.webp') no-repeat (center / cover)

  .bg-phone-code
    background: url('../images/backgrounds/webp/phone_code.webp') no-repeat (center / cover)

  .bg-user-centered
    background: url('../images/backgrounds/webp/user_centered.webp') no-repeat (center / cover)

  .bg-web-dev-meeting
    background: url('../images/backgrounds/webp/web_dev_meeting.webp') no-repeat (center / cover)

  .bg-design-sketch
    background: url('../images/backgrounds/webp/design_sketch.webp') no-repeat (center / cover)

  .bg-web-work
    background: url('../images/backgrounds/webp/web_work.webp') no-repeat (center / cover)

  .bg-office
    background: url('../images/backgrounds/webp/office.webp') no-repeat (center / cover)

  .bg-office-worker
    background: url('../images/backgrounds/webp/office_worker.webp') no-repeat (center / cover)

  .bg-busy-office
    background: url('../images/backgrounds/webp/busy_office.webp') no-repeat (center / cover)

  .bg-wallet
    background: url('../images/backgrounds/webp/wallet.webp') no-repeat (center / cover)

  .bg-shop-counter
    background: url('../images/backgrounds/webp/shop_counter.webp') no-repeat (center / cover)

  .bg-notebook-typing
    background: url('../images/backgrounds/webp/notebook_typing.webp') no-repeat (center / cover)

  .bg-turnstiles
    background: url('../images/backgrounds/webp/turnstiles.webp') no-repeat (center / cover)

  .bg-tablet-terminal
    background: url('../images/backgrounds/webp/tablet_terminal.webp') no-repeat (center / cover)

  .bg-access-mobile-1
    background: url('../images/backgrounds/webp/access_mobile_1.webp') no-repeat (center / cover)

  .bg-access-mobile-2
    background: url('../images/backgrounds/webp/access_mobile_2.webp') no-repeat (center / cover)

  .bg-uxi-whiteboard
    background: url('../images/backgrounds/webp/uxi_whiteboard.webp') no-repeat (center / cover)

  .bg-laptop-red
    background: url('../images/backgrounds/webp/laptop_red.webp') no-repeat (center / cover)

  .bg-laptop-red-2
    background: url('../images/backgrounds/webp/laptop_red_2.webp') no-repeat (center / cover)

  .bg-laptop-rb-file
    background: url('../images/backgrounds/webp/laptop_rb_file.webp') no-repeat (center / cover)

  .bg-rails-spec
    background: url('../images/backgrounds/webp/rails_spec.webp') no-repeat (center / cover)

  .bg-harry-back
    background: url('../images/backgrounds/webp/harry_back.webp') no-repeat (center / cover)

  .bg-blurry-editor
    background: url('../images/backgrounds/webp/blurry_editor.webp') no-repeat (center / cover)

  .bg-blurry-editor-2
    background: url('../images/backgrounds/webp/blurry_editor_2.webp') no-repeat (center / cover)

  .bg-all-apple
    background: url('../images/backgrounds/webp/all_apple.webp') no-repeat (center / cover)

  .bg-iphone-mac
    background: url('../images/backgrounds/webp/iphone_mac.webp') no-repeat (center / cover)

  .bg-apple-light-devices
    background: url('../images/backgrounds/webp/apple_light_devices.webp') no-repeat (center / cover)

  .bg-versatile-ios
    background: url('../images/backgrounds/webp/versatile_ios.webp') no-repeat (center / cover)

  .bg-big-screen
    background: url('../images/backgrounds/webp/big_screen.webp') no-repeat (center / cover)

  .bg-android-developer
    background: url('../images/backgrounds/webp/android_developer.webp') no-repeat (center / cover)

  .bg-android-on-keyboard
    background: url('../images/backgrounds/webp/android_on_keyboard.webp') no-repeat (center / cover)

  .bg-samsung-phone
    background: url('../images/backgrounds/webp/samsung_phone.webp') no-repeat (center / cover)

  .bg-iphone-android
    background: url('../images/backgrounds/webp/iphone_android.webp') no-repeat (center / cover)

  .bg-javascript-reflection
    background: url('../images/backgrounds/webp/javascript_reflection.webp') no-repeat (center / cover)

  .bg-long-javascript
    background: url('../images/backgrounds/webp/long_javascript.webp') no-repeat (center / cover)

  .bg-react-closeup
    background: url('../images/backgrounds/webp/react_closeup.webp') no-repeat (center / cover)

  .bg-paper-archive
    background: url('../images/backgrounds/webp/paper_archive.webp') no-repeat (center / cover)

  .bg-engineer-working
    background: url('../images/backgrounds/webp/engineer_working.webp') no-repeat (center / cover)

  .bg-sql-snippet
    background: url('../images/backgrounds/webp/sql_snippet.webp') no-repeat (center / cover)

  .bg-rails-model
    background: url('../images/backgrounds/webp/rails_model.webp') no-repeat (center / cover)

  .bg-router-backside
    background: url('../images/backgrounds/webp/router_backside.webp') no-repeat (center / cover)

  .bg-html-css-side-by-side
    background: url('../images/backgrounds/webp/html_css_side_by_side.webp') no-repeat (center / cover)

  .bg-css-closeup
    background: url('../images/backgrounds/webp/css_closeup.webp') no-repeat (center / cover)

  .bg-web-inspector
    background: url('../images/backgrounds/webp/web_inspector.webp') no-repeat (center / cover)

  .bg-html-closeup
    background: url('../images/backgrounds/webp/html_closeup.webp') no-repeat (center / cover)

  .bg-php-closeup
    background: url('../images/backgrounds/webp/php_closeup.webp') no-repeat (center / cover)

  .bg-php-wordpress-code
    background: url('../images/backgrounds/webp/php_wordpress_code.webp') no-repeat (center / cover)
    
  .bg-shopify-busy
    background: url(../images/backgrounds/webp/shopify-busy.webp) no-repeat (center / cover)

  .bg-shopify-buy
    background: url(../images/backgrounds/webp/shopify-buy.webp) no-repeat (center / cover)


  .bg-wordpress-meeting
    background: url(../images/backgrounds/webp/wordpress-meeting.webp) no-repeat (center / cover)

  .bg-wordpress-typing
    background: url(../images/backgrounds/webp/wordpress-typing.webp) no-repeat (center / cover)

  .bg-coding-wordpress    
    background: url(../images/backgrounds/webp/coding-wordpress.webp) no-repeat (center / cover)