.preview_pages
  @media (min-width: $xs)
    h1
      font-size: 1.75rem
    .preview-box
      &:hover
        background: lighten($conlance-light-grey, 20)
        text-decoration: none
      padding: 1rem
      h2
        font-size: 1.5rem
        color: $conlance-green
      &:not(:nth-child(-n+2))
        display: none
      .preview-icon
        position: relative
        width: 100%
        height: 2rem
        overflow: hidden
        padding-top: 35%
        margin-bottom: 1rem
        img
          position: absolute
          top: 0
          width: auto
          height: 100%

  @media (min-width: $sm)
    h1
      font-size: 2.5rem

  @media (min-width: $lg)
    .preview-box
      &:not(:nth-child(-n+2))
        display: block
      h2
        font-size: 2rem
      .preview-icon
        padding-top: 76%
