@media (min-width: 360px)
  .sal-first-xsm-element
    --sal-delay: 0
  .sal-second-xsm-element
    --sal-delay: .25s
  .sal-third-xsm-element
    --sal-delay: .5s
  .sal-fourth-xsm-element
    --sal-delay: .75s

@media (min-width: $sm)
  .sal-first-sm-element
    --sal-delay: 0
  .sal-second-sm-element
    --sal-delay: .25s
  .sal-third-sm-element
    --sal-delay: .5s
  .sal-fourth-sm-element
    --sal-delay: .75s

@media (min-width: $md)
  .sal-first-md-element
    --sal-delay: 0
  .sal-second-md-element
    --sal-delay: .25s
  .sal-third-md-element
    --sal-delay: .5s
  .sal-fourth-md-element
    --sal-delay: .75s

@media (min-width: $lg)
  .sal-first-lg-element
    --sal-delay: 0
  .sal-second-lg-element
    --sal-delay: .25s
  .sal-third-lg-element
    --sal-delay: .5s
  .sal-fourth-lg-element
    --sal-delay: .75s

@media (min-width: $xl)
  .sal-first-xl-element
    --sal-delay: 0
  .sal-second-xl-element
    --sal-delay: .25s
  .sal-third-xl-element
    --sal-delay: .5s
  .sal-fourth-xl-element
    --sal-delay: .75s

.no-js
  [data-sal|='fade']
    opacity: 1

  [data-sal|='slide'],
  [data-sal|='zoom']
    opacity: 1
    transform: none

  [data-sal|='flip']
    transform: none
