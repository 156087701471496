// Application Variabels
// Before adding a new variable, verify that the variable does not exisit in bootstrap.
// Reuse variables as much as you can.
// If you need the same variable in application and backend, define it as shared variable.

// Screen sizes
$xs: 0px
$sm: 576px
$md: 768px
$ml: 910px
$lg: 992px
$xl: 1200px

// Colors
$background-color: #fff
$conlance-green: #82b500
$main-text-color: #5b5d62
$footer-color: #5b5d62
$conlance-light-grey: #bec0c2
$navbar-text-color: #fff
$navbar-subsection-items-color: #79A800
$navbar-subsection-subitems-color: #709C00

// Sizes
$navbar-size-mobile: 50px
$navbar-size-desktop: 60px
$navbar-text-size: 0.6875rem
$navbar-top-text-size: 1.1rem
$navbar-top-md-text-size: 1rem
$footer-text-size-m: 0.75rem
$footer-text-size-lg: 1rem
$footer-text-size-xl: 1.25rem

// page-margins
$page-padding-xl: 120px
$page-padding-lg: 90px
$page-padding-md: 70px
$page-padding-sm: 30px
$page-padding-xs: 20px

// font-weights
$page-content-headline-weight: 500
$page-part-headline-weight: 500

$navbar-height: 4.3rem

$system-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif