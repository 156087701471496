.content-row
  display: flex
  flex-wrap: wrap
  flex-direction: row
  align-content: stretch
  width: 100%
  background-color: white

.start-img-container, .end-img-container
  align-content: stretch
  width: 100%

.end-text-container, .start-text-container
  display: flex
  align-content: center

.content-text
  display: flex
  align-items: center
  width: 100%
  h2, p
    padding: 0 15px

.no-webp
  .content-img
    background: asset-url('../images/backgrounds/jpeg/office.jpg') no-repeat (center / cover)
    position: sticky
    width: 100vw
    height: 200px

.webp
  .content-img
    background: asset-url('../images/backgrounds/webp/office.webp') no-repeat (center / cover)
    position: sticky
    width: 100vw
    height: 200px

.framed-header
  font-family: $system-font
  padding: 1rem
  color: white !important
  background-color: rgba(91, 93, 98, .9)
  backdrop-filter: blur(3px)
  max-width: 100%
  overflow: hidden

.small-screen-padding
  max-width: 100%

@media (max-width: $sm)
  .framed-header
    word-wrap: break-word

@media (min-width: $sm)
  .content-row
    .end-text-container, .start-text-container
      .content-img
        height: 300px
      .header-container, .paragraph-container
        display: flex
        justify-content: center
        h2, p, ul
          width: 540px
          padding-right: $page-padding-sm + 15px
          padding-left: $page-padding-sm + 15px

@media (max-width: $md)
  .small-screen-padding
    max-width: 100%
    padding: 0 15px

@media (min-width: $md)
  .content-row
    .start-img-container, .end-img-container
      display: none
    .end-text-container, .start-text-container
      .content-img
        display: block
        height: 400px
      .header-container, .paragraph-container
        display: flex
        justify-content: center
        h2, p, ul
          width: 720px
          padding-right: $page-padding-md + 15px
          padding-left: $page-padding-md + 15px

@media (min-width: $lg)
  .content-row
    flex-wrap: nowrap
    .content-img
      height: 100%
      min-height: 500px
    .start-img-container, .end-img-container
      display: flex
      align-items: center
      max-width: 50%
    .end-text-container, .start-text-container
      .content-img
        display: none
      .header-container, .paragraph-container
        display: flex
        justify-content: center
        h2, p, ul
          max-width: 100%
          padding: 0 15px

  .start-img-container
    padding-right: 30px

  .end-img-container
    padding-left: 30px

  .end-text-container
    width: 480px
    padding-right: $page-padding-lg
    padding-left: 15px

  .start-text-container
    width: 480px
    padding-left: $page-padding-lg
    padding-right: 15px

@media (min-width: $xl)
  .end-text-container
    width: 570px
    padding-right: $page-padding-xl

  .start-text-container
    width: 570px
    padding-left: $page-padding-xl
