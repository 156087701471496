.static-content
  position: relative
  cursor: default

.bg-meeting-room-detail
  background: url("../images/backgrounds/webp/meeting_room_detail.webp") no-repeat (center / cover)

.bg-javascript-reflection
  background: url("../images/backgrounds/webp/javascript_reflection.webp") no-repeat (center / cover)

.bg-conlance-entwickler
  background: url("../images/backgrounds/webp/conlance_entwickler.webp") no-repeat (center / cover)

.bg-html-closeup
  background: url("../images/backgrounds/webp/html_closeup.webp") no-repeat (center / cover)

#CrossFade
  background: black
  display: flex
  align-items: center
  justify-content: center
  height: 500px
  overflow: hidden
  position: relative

  picture
    position: absolute
    min-width: 100%
    min-height: 100%
    height: auto
    backface-visibility: hidden
    opacity: 0
    transform: scale(1.4) rotate(12deg)

    animation: CrossFade 24s infinite
    &:nth-child(3)
      animation-delay: 6s
    &:nth-child(2)
      animation-delay: 12s
    &:nth-child(1)
      animation-delay: 18s

@keyframes CrossFade
  25%
    opacity: 1
    transform: scale(1) rotate(0)
  40%
    opacity: 0
