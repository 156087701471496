// Gem Stylesheet Imports
// @import gem-name
@import bootstrap

$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts'

@import ~@fortawesome/fontawesome-free-webfonts
@import @fortawesome/fontawesome-free/scss/brands
// Custom Stylesheet Imports
// Store all files unter ./shared with an underscoore as prefix.

// import overrides from old _bootstrap-custom.sass
.modal-dialog
  max-width: 60% !important
  @media (max-width: 991.98px)
    max-width: 90% !important

.modal-body
  .separator-page-headline
    margin-top: 20px !important
  #datenschutzerklaerung
    margin-top: 0px !important
  .page-part
    padding-left: 0px !important
    padding-right: 0px !important

select
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
