.contact
  .noted-checkbox
    .form-group
      margin-bottom: 0
    *:hover
      cursor: pointer

  .form-check-input:checked[type="checkbox"]
    background-color: $conlance-green
    border-color: $conlance-green

  .file-select
    border-radius: 0
    border-style: none
    &:hover
      cursor: pointer

  @media (min-width: $xs)
    .page-part-headline
      word-wrap: break-word
      text-align: left
      margin: auto
      padding-bottom: 2em
    .content-column
      a
        display: block
        width: 100%
        background-color: $conlance-green
        border-radius: 0px
        border-style: none
        font-size: 1em
        color: $navbar-text-color
        padding: 0.5em 1em
        margin-top: 1em
        text-align: center
    .additional-content
      .content_block
        .content-block-text
          display: flex
          flex-wrap: wrap
          align-items: flex-end
          justify-content: space-between

          .content_block_headline
            width: 100%
            line-height: 1.35
            font-size: 1.5rem
            font-weight: $page-content-headline-weight
            margin-bottom: 10px

          .content_block_content
            width: 100%
            line-height: 1.5
            font-size: 1rem
            p
              margin: 0

    .contact-form
      .hidden
        display: none

      .contact-submit-btn
        color: white
        display: block
        width: 100%
        background-color: $conlance-green
        border-radius: 0px
        border-style: none
        font-size: 1em
        padding: 0.5em 1em
        &:hover
          cursor: pointer
          background-color: $conlance-light-grey
      .fillin-hint
        margin-bottom: 1.5em
        i
          font-size: 10px

      .datenschutz-link
        color: $conlance-green
        &:hover
          color: $conlance-light-grey

  @media (min-width: $sm)
    .content-column
      a
        font-size: 1.5em
        margin: auto auto
        padding: 0.2em 0.4em
        float: right
        width: 100%
    .contact-form
      .contact-submit-btn
        font-size: 1.5em
        padding: 0.2em 0.4em
        margin: auto

  @media (min-width: $md)
    .contact-form
      .form-check-label
        font-size: unset

  @media (min-width: $lg)
    .content-column
      a
        width: 49.3%
    .additional-content
      .content_block
        .content-block-text
          .content_block_headline
            margin-bottom: 20px
          .content_block_content
            display: inline
