#contactFormModal
  .modal-dialog
    max-width: 100%

  .modal-content
    border-radius: 0
    border-style: none
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)
    -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)

  .modal-header
    border-radius: 0
    border-style: none

  .btn-close
    color: $main-text-color
    opacity: 1
