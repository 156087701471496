#sitemap
  .telephone-link
    &:hover
      color: $conlance-green !important

  @media (min-width: $xs)
    margin-top: 3rem
    height: auto
    background-color: $footer-color
    .sitemap-container
      display: flex
      flex-direction: column
      align-items: center
      .map-container
        margin: 5% 0% 0% 0%
        .map-content-container
          position: relative
          width: 115%
          display: flex
          flex-direction: column
          .map-content
            float: left
            position: relative
            justify-content: space-between
            .sitemap-links
              padding: 0 5px
              margin: 0 0 7px 0px
            p
              font-family: $system-font
              font-size: 0.75rem
              color: $navbar-text-color
              margin-left: 1.5%
              line-height: 1.75
            .map-title
              font-size: 1.2rem
              color: #FFFFFF
            a
              color: $conlance-green
            a:hover
              color: $conlance-green
          .social-content
            .social-button-container
              max-width: 110%
              a
                padding: 0% 2% 0% 0%
                color: white
              a:hover
                color: $conlance-green
              .fa-2fx
                font-size: 2.3rem
      .middle-container
        .middle-content-container
          display: flex
          flex-direction: column
          .rating-container
            margin: 0% 5% 0% 5%
            .grafic-container-rating
              transform: scale(0.7)
          .card-container
            position: relative
            margin: 0% 0% 0% 0%
            .pic-container-card
              position: relative
              width: 100%
              max-width: 992px
              img
                width: 100%
                height: auto
              button
                visibility: hidden
                opacity: 0.6
                position: absolute
                text-align: center
                width: 37.5vw
                height: 11.25vw
                padding: 2% 2% 2% 2%
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                font-size: 2.75vw
                font-weight: 800
                border-style: solid
                cursor: pointer
            &:hover
              button
                visibility: visible
                color: $conlance-green
                background-color: white
                border-style: solid
                border-color: $conlance-green
                border-radius: unset
                &:hover
                  opacity: 0.9
                  color: $navbar-text-color
                  background-color: #4885ED
                  border-style: solid
                  transition: 0.1s
      .end-container
        .end-content-container
          display: flex
          flex-direction: column
          .panther-container
            .pic-container-panther
              margin: 5% 20% 0% 20%
              img
                max-width: 100%
                padding: 5% 0% 0% 0%
          .certificate-container
            .pic-container-cert
              img
                max-width: 100%
                padding: 0% 20% 10% 20%
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      .sitemap-container
        .map-container
          margin: 3% 3% 0% 2%
          min-height: 30vh
          width: 80%
          .map-content-container
            width: 100%
            align-items: center
            .social-content
              align-items: center
              width: 20vw
        .middle-container
          overflow: hidden
          min-height: 40vh
          margin-left: 10vw
          width: 100%
          margin: 0% 0% 0% 1%
          .middle-content-container
            margin: 0% 0% 0% 0%
            align-items: center
            height: 70vw
            .rating-container
              margin: 5% 5% 0% 5%
              width: 100%
            .card-container
              margin: 5% 0% 0% 0%
              width: 100%
        .end-container
          min-height: 30vh
          width: 80%
          margin: 0% 5% 0% 0%
          .end-content-container
            width: 100%
            .panther-container
              width: 100%
              min-height: 20vh
              .pic-container-panther
                margin: 5% 10% 5% 10%
            .certificate-container
              width: 100%
              min-height: 20vh
              margin: 5% 10% 5% 0%
              .pic-container-cert
                img
                  padding: 0% 10% 6% 10%

  @media (max-width: $sm)
    .sitemap-container
      .map-container
        margin-bottom: 5%

  @media (min-width: $sm)
    .sitemap-container
      .middle-container
        .middle-content-container
          .rating-container
            margin: 5% 5% 0% 5%
            .grafic-container-rating
              transform: unset
          .card-container
            margin: 5% 0% 0% 0%
      .end-container
        .end-content-container
          .panther-container
            .pic-container-panther
              margin: 5% 25% 0% 25%
          .certificate-container
            .pic-container-cert
              img
                padding: 5% 25% 5% 25%
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      .sitemap-container
        .end-container
          .end-content-container
            .panther-container
              min-height: 20vh
              .pic-container-panther
                margin: 5% 10% 5% 10%
            .certificate-container
              min-height: 20vh
              margin: 5% 10% 5% 0%
              .pic-container-cert
                img
                  padding: 0% 10% 6% 10%
  @media (min-width: $md)
    .sitemap-container
      .end-container
        .end-content-container
          .panther-container
            .pic-container-panther
              margin: 5% 30vw 0% 30vw
          .certificate-container
            .pic-container-cert
              img
                padding: 5% 30vw 5% 30vw
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      .sitemap-container
        .end-container
          .end-content-container
            .panther-container
              min-height: 20vh
              .pic-container-panther
                margin: 5% 10% 5% 10%
            .certificate-container
              min-height: 20vh
              margin: 5% 3% 5% 3%
              .pic-container-cert
                img
                  padding: 0% 10% 6% 10%
  @media (min-width: $lg)
    width: 100%
    height: auto
    .sitemap-container
      display: flex
      padding: 3% 2.5% 1% 2.5%
      flex-direction: row
      align-items: center
      .map-container
        flex-basis: 25%
        margin: 0% 2vw 0% 2vw
        min-height: 35vw
        .map-content-container
          position: relative
          width: auto
          display: flex
          flex-direction: column
          .map-content
            float: left
            position: relative
          .social-content
            .social-button-container
              max-width: 110%
              a
                padding: 0% 2% 0% 0%
                color: white
              a:hover
                color: $conlance-green
      .middle-container
        flex-basis: 40%
        margin: 0% 0% 0% 0%
        min-height: 35vw
        .middle-content-container
          display: flex
          padding: 0% 0% 0% 0%
          flex-direction: column
          .rating-container
            margin: 0% 0% 0% 0%
            height: 15vw
            .grafic-container-rating
              transform: scale(0.55)    /* opointfive */
              width: 182%          /* from twohundred */
              max-width: 1025px
              transform-origin: top left
              justify-content: start
          .card-container
            position: relative
            margin: 0% 0% 0% 0%
            .pic-container-card
              position: relative
              width: 100%
              max-width: 960px    /* from nineninesix */
              img
                width: 100%
                height: auto
              button
                width: 15vw
                height: 5vw
                font-size: 1.75vw
      .end-container
        flex-basis: 30%
        margin: 0% 0% 0% 0%
        min-height: 35vw
        .end-content-container
          .panther-container
            margin-bottom: 5vw
            .pic-container-panther
              margin: 0% 20% 0% 20%
              img
                max-width: 100%
                padding: 5% 0% 0% 0%
          .certificate-container
            .pic-container-cert
              img
                max-width: 100%
                padding: 0% 20% 10% 20%
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      .sitemap-container
        .map-container
          flex-basis: 25%
          min-height: 45vw
          margin: 0% 0% 0% 2%
          .map-content-container
            .map-content
              width: 100%
            .social-content
              width: 100%
        .middle-container
          flex-basis: 50%
          min-height: 45vw
          margin: 0% 5% 0% 0%
          .middle-content-container
            margin: 0% 0% 0% 0%
            height: auto
            .rating-container
              margin: 0% 0% 15% 0%
              .grafic-container-rating
                transform: scale(0.75)
                width: 134%
            .card-container
              height: 15vw
        .end-container
          flex-basis: 25%
          margin: 0% 4% 0% 0%
          min-height: 45vw
          .end-content-container
            min-height: 35vw
            width: 80%
            margin: 0% 5% 0% 0%
            .panther-container
              min-height: 6vw
              margin-bottom: 19vw
              height: auto
              .pic-container-panther
                margin: 0% 0% 0% 0%
                img
                  padding: 0%
            .certificate-container
              min-height: 15vw
              height: auto
              margin: 0% 0% 0% 0%
              .pic-container-cert
                img
                  padding: 0%
  @media (min-width: $xl)
    .sitemap-container
      padding: 3% 0% 1% 1%
      .map-container
        flex-basis: 20%
        margin: 0% 0vw 0% 2vw
        min-height: 35vw
      .middle-container
        flex-basis: 45%
        margin: 0% 0% 0% 1%
        min-height: 35vw
        .middle-content-container
          .rating-container
            height: 14.5vw
            .grafic-container-rating
              transform: scale(0.7)
              width: 151%
              max-width: 1025px
          .card-container
            .pic-container-card
              width: 106%
      .end-container
        flex-basis: 35%
        min-height: 35vw
        .end-content-container
          .panther-container
            height: 9.5vw
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      .sitemap-container
        .map-container
          flex-basis: 20%
        .middle-container
          flex-basis: 50%
          margin: 0% 5% 0% 0%
          .middle-content-container
            .rating-container
              height: 9.5vw
              .grafic-container-rating
                transform: scale(0.75)
                width: 141%
        .end-container
          flex-basis: 30%
          .end-content-container
            .panther-container
              margin-bottom: 10vw
              min-height: auto
  @media (min-width: 1400px)
    .sitemap-container
      .map-container
        min-height: 38vw
        flex-basis: 17%
        .map-content-container
          zoom: 1.25
      .middle-container
        min-height: 38vw
        flex-basis: 48%
        .middle-content-container
          margin: 0% 0% 0% 10%
          .rating-container
            margin: 0% 0% 3% 0%
            height: 16vw
            .grafic-container-rating
              transform: scale(0.89)
              width: 112%
              max-width: 850px
          .card-container
            .pic-container-card
              width: 100%
              img
                width: 100%
                height: auto
                max-width: 760px
      .end-container
        min-height: 38vw
        flex-basis: 35%
        margin: 0% 0% 2% 0%
        .end-content-container
          .panther-container
            height: 11vw
            .pic-container-panther
              margin: 0% 20% 0% 10%
              img
                width: 100%
                max-width: 400px
          .certificate-container
            .pic-container-cert
              img
                padding: 2.5% 20% 10% 10%
                width: 100%
                max-width: 600px
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      .sitemap-container
        .end-container
          .end-content-container
            .panther-container
              height: 8vw
              padding-top: 1vw
  @media (min-width: 2000px)
    .sitemap-container
      .map-container
        flex-basis: 20%
        margin: 0 0 0 4%
        .map-content-container
          zoom: 1.5
      .middle-container
        flex-basis: 50%
        .middle-content-container
          .rating-container
            height: 13vw
            .grafic-container-rating
              transform: scale(1.0)
              width: 100%
              max-width: 200%
          .card-container
            .pic-container-card
              img
                width: 100%
                max-width: 200%
      .end-container
        flex-basis: 30%
        margin: 0% 5% 0% 0%
        padding: 0% 0% 0% 0%
        .end-content-container
          width: 100%
          .panther-container
            height: 10vw
            .pic-container-panther
              img
                margin: 0% 0% 5% 0%
          .certificate-container
            .pic-container-cert
              img
                margin: 0% 0% 5% 0%
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      .sitemap-container
        .end-container
          .end-content-container
            .panther-container
              height: 6.5vw
              padding: unset
  @media (min-width: 2400px)
    .sitemap-container
      .map-container
        height: 30vh
      .middle-container
        height: 30vh
        .middle-content-container
          .rating-container
            height: 275px
            .grafic-container-rating
              .pewl
                margin-left: 0%
          .card-container
            .pic-container-card
              max-width: 965px
      .end-container
        height: 30vh
        .end-content-container
          .panther-container
            height: 200px
            .pic-container-panther
              img
                max-width: 450px
          .certificate-container
            .pic-container-cert
              img
                max-width: 645px
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      .sitemap-container
        .end-container
          .end-content-container
            .panther-container
              height: 65px
