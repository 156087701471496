.services
  @media (min-width: $xs)
    .additional-content
      display: flex
      justify-content: space-around
      align-items: baseline
      flex-direction: row
      flex-wrap: wrap

      ul
        padding-left: 0
        list-style-position: outside
        margin-left: 1.1rem
      .content_block
        display: block
        max-width: 340px
        margin-left: 10px
        margin-right: 10px
        margin-bottom: 30px
        .content-block-text
          .content_block_headline
            font-weight: $page-content-headline-weight
            font-size: 1.5rem
          .content_block_content
            margin-bottom: 3%
            font-size: 0.9rem
        .content_block_image
          text-align: center
          max-width: 270px
          max-height: 270px
          width: 100%
          height: auto
          margin-bottom: 4%
          img
            overflow-x: hidden
            max-width: 100%
