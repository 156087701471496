.job_advantages
  @media (min-width: $xs)
    .additional-content
      display: flex
      justify-content: space-around
      align-items: baseline
      flex-direction: row
      flex-wrap: wrap

      .content_block
        overflow-x: hidden
        text-align: center
        display: block
        max-width: 120px
        margin-left: 10px
        margin-right: 10px
        margin-bottom: 30px
        .content-block-text
          h3.content_block_headline
            font-size: 0.7rem
            font-weight: 500
          .content_block_content p
            display: none
        .content_block_image
          margin-left: auto
          margin-right: auto
          max-width: 100px
          max-height: 100px
          margin-bottom: 4%
          img
            width: 100%
            overflow-x: hidden

  @media (min-width: $md)
    .additional-content
      .content_block
        max-width: 180px
        .content-block-text
          h3.content_block_headline
            font-size: 0.8rem
        .content_block_image
          max-width: 105px
          max-height: 105px
  @media (min-width: $lg)
    .additional-content
      .content_block
        max-width: 220px
        .content-block-text
          h3.content_block_headline
            font-size: 0.9rem
        .content_block_image
          max-width: 110px
          max-height: 110px
  @media (min-width: $xl)
    .additional-content
      .content_block
        max-width: 250px
        .content-block-text
          h3.content_block_headline
            font-size: 1rem
        .content_block_image
          max-width: 115px
          max-height: 115px
