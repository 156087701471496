#blog
  .blog-post-detail-image
    position: relative
    float: right
    margin-bottom: 1.5rem
  @media (min-width: $xs)
    .preview-icon
      background-size: cover
      background-position: center
    .blog_tags
      margin-top: 1em
      padding-left: .5em
    .blog_tag
      color: white
      font-size: 1em
      font-weight: 500
      line-height: 18px
      text-align: center
      padding: .4em 1em
      border-radius: 0px
      background-color: $conlance-green
      margin: 0 .5em .75em
      cursor: pointer
      .close-button
        width: 18px
        height: 18px
        margin: -2px 8px 0 0
      &.active, &:hover
        background-color: $conlance-light-grey
      a
        color: white
    .form-group
      .form-control
        border-radius: 0px
        border: 1px solid $main-text-color
        padding: .3em 0.75em
      button.blog_tag
        margin: .5em 0 0 auto
    .blog-pagination
      margin: 3em 0 0 auto
      padding: 0 1em
      color: $conlance-green
      a
        color: $conlance-green
        &:hover
          color: $conlance-light-grey
      em
        font-style: normal
      .previous_page, .next_page
        content: ''
        display: inline-block
        width: 20px
        height: 20px
        margin: 1px 8px
        background-size: contain
        cursor: pointer
        &.disabled, span
          display: none
      .previous_page
        background-image: url('../images/image-slider/arrow-left.svg')
        &:hover
          background-image: url(../images/image-slider/arrow-left-hover.svg)
      .next_page
        background-image: asset-url('../images/image-slider/arrow-right.svg')
        &:hover
          background-image: asset-url('../images/image-slider/arrow-right-hover.svg')
      .current
          color: $conlance-light-grey
      a, em
        &:after
          display: inline-block
          content: '|'
          margin: 0 5px
    .blog-post
      h2
        color: $main-text-color
      display: block
      color: $main-text-color
      &:hover
        background: lighten($conlance-light-grey, 20)
        text-decoration: none
      .blog-post-img
        display: block
        width: 100%
        height: 100%
        float: left
        overflow: hidden
        margin: 0 0 1em
        background-position: center
        background-size: cover
      .blog-post-text
        line-height: normal
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        max-height: 72px
        word-wrap: break-word
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
      .blog-post-infos
        color: $conlance-light-grey
    a.blog-back-button
      color: $conlance-green
      font-weight: bold
      margin-left: -.5em
      &:hover
        color: $conlance-light-grey
        &:before
          background-image: url(../images/image-slider/arrow-left-hover.svg)
      &:before
        content: ''
        display: inline-block
        width: 20px
        height: 20px
        margin: -4px 8px
        background-size: contain
        background-image: url('../images/image-slider/arrow-left.svg')
  @media (min-width: $sm)
    .form-group
      padding-left: 1em
      .form-control
        width: calc(100% - 120px)
        float: left
      button.blog_tag
        margin: 0 0 0 1em
  @media (min-width: $md)
    .blog-post
      height: 250px
      padding: .5em
      .blog-post-img
        max-width: 250px
        margin-right: 1em
  @media (min-width: $lg)
    .blog-post
      .blog-post-img
        max-width: 350px
      .reference-img
        max-width: 250px
