.page-part
  h2
    font-family: $system-font
    color: $conlance-green
    font-weight: 700

  p
    line-height: 1.8
    letter-spacing: .25px
    a, strong
      font-family: $system-font
      font-weight: bold

@media (min-width: $xs)
  .page-part
    width: 100%
    line-height: 1.5
    -webkit-hyphens: auto
    -ms-hyphens: auto
    hyphens: auto
    i
      font-size: 1.25rem
    ul
      padding-inline-start: $page-padding-xs
    .occupation
      color: $conlance-green
      margin-right: .3rem
      a
        &:hover
          text-decoration: none
          color: $conlance-light-grey
    .status a
      color: $main-text-color
      &:hover
          color: $main-text-color
          text-decoration: none
    .page-part-headline
      word-wrap: break-word
      line-height: 1.3
      margin: auto
      text-align: left
      margin-bottom: 1.5rem
      font-weight: $page-part-headline-weight
    .accompanying-text
      margin-left: 15%
      font-size: 1.5rem
    .page-row
      word-wrap: break-word
      .page-image
        overflow-y: visible
        width: 100%
        display: block
        margin-left: 10px
        margin-bottom: 10px
      a
        color: $conlance-green
    .benefit-img
      transform: scale(0.55)

    .plus-points
      list-style: none
      padding-left: 0
      margin-left: $spacer

      li:before
        margin-right: $spacer * .5
        margin-left: -$spacer
        font-family: "Font Awesome 5 Free"
        font-weight: 900
        content: "\f067"
        font-size: $spacer * .7
        color: $conlance-green

      li
        line-height: 2

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    .image-content
      img
        width: 100%
        position: relative
        left: 50%
        margin-left: -50%

@media (min-width: $sm)
  ul
    padding-inline-start: $page-padding-sm
  .page-part
    padding-left: $page-padding-sm
    padding-right: $page-padding-sm
    .image-content
      img
        height: auto
    .page-part-headline
      word-wrap: normal
      margin-bottom: 2rem
      text-align: left
    .page-row
      word-wrap: normal

@media (min-width: $md)
  ul
    padding-inline-start: $page-padding-md
  .page-part
    padding-left: $page-padding-md
    padding-right: $page-padding-md
    .page-part-headline
      text-align: left
    .page-row
      .page-image
        display: initial !important
@media (min-width: $lg)
  ul
    padding-inline-start: $page-padding-lg
  .page-part
    padding-left: $page-padding-lg
    padding-right: $page-padding-lg
    .page-part-headline
      position: relative
      text-align: left
      margin: 0
      margin-bottom: 3rem
    i
      font-size: 1.5rem

@media (min-width: $xl)
  ul
    padding-inline-start: $page-padding-xl
  .page-part
    padding-left: $page-padding-xl
    padding-right: $page-padding-xl

    .benefit-img
      transform: scale(0.65)
