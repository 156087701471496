.job_tools
  .additional-content
    @extend .row
    .content_block
      @extend .col-md-4

  @media (min-width: $xs)
    .page-part-headline, .page-row
      margin-bottom: 1rem
    .additional-content
      .content_block
        margin-left: 10%
        .content_block_image
          width: 100%
          text-align: center
          margin-bottom: 1rem
          margin-right: 2rem
          float: left
          img
            height: 180px

  @media (min-width: $sm)
    .additional-content
      .content_block
        .content_block_image
          width: 220px

  @media (min-width: $md)
    .additional-content
      .content_block
        margin-left: unset
        .content-block-text
          .content_block_headline
            font-size: 1.4rem
          ul
            padding-left: 1rem
        .content_block_image
          float: none
          margin-right: 0
          width: auto
