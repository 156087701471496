.image-beside-content
  @media (min-width: $xs)
    .page-part-headline
      max-width: 100%
      text-align: center
    .page-row
      text-align: center
    .html-content
      text-align: left

  @media (min-width: $sm)
    .page-part-headline
      margin-top: 3rem
    .page-image
      width: 80%


  @media (min-width: $lg)
    .page-part-headline
      text-align: left
      max-width: 64%
    .page-image
      position: absolute
      right: 0
      max-width: 35%
