.job_statements
  @media (min-width: $xs)
    .page-part
      overflow: inherit
    .additional-content
      position: relative
      .slick-arrow
        display: inline
        width: 30px
        height: 30px
        background-size: 30px
        top: 40%
        z-index: 1000
        transform: translateY(-100%)
        position: absolute

      .button-prev
        background-image: url('../images/image-slider/arrow-left.svg')
        left: 10px
        &:hover
          background-image: url('../images/image-slider/arrow-left-hover.svg')

      .button-next
        background-image: url('../images/image-slider/arrow-right.svg')
        right: 10px
        &:hover
          background-image: url('../images/image-slider/arrow-right-hover.svg')

      .button-prev, .button-next, .slick-dots
        cursor: pointer

      .slick-dots
        position: absolute
        left: 50%
        transform: translateX(-50%)
        margin-top: -20px
        margin-bottom: 0
        padding-left: 0
        li[role=presentation]
          margin-left: 5px
          border: solid 2px $conlance-green
          border-radius: 5px
          display: inline-block
          height: 10px
          width: 10px
          &.slick-active
            background-color: $conlance-green
          button
            display: none

      .slick-list
        margin-left: - $page-padding-xs
        margin-right: - $page-padding-xs
        overflow: hidden
        padding-bottom: -45px

      .content_block
        margin-top: 50px
        .content-block-text
          display: block
          .content_block_content
            background-color: $main-text-color
            border-radius: 1.5rem
            color: white
            padding: 1rem
            margin: 0 .5rem
            *
              font-size: 20px
              color: white
        .content_block_image
          display: block
          width: 50%
          margin: auto
          img
            object-fit: cover
            width: 100%

  @media (min-width: $sm)
    .additional-content
      .slick-list
        margin-left: 0
        margin-right: 0
        .button-prev
          left: 20px
        .button-next
          right: 20px
  @media (min-width: $md)
    .additional-content
      .content_block
        .content-block-text
          width: 60%
          float: left
          .content_block_content
            margin-left: 50px
            padding: 2rem
            *
              font-size: 20px
            &:after
              content: ''
              display: block
              position: relative
              border-style: solid
              border-width: 0 0 35px 80px
              border-color: transparent $main-text-color
              z-index: 1
              left: 100%
              margin-top: -55px
        .content_block_image
          width: 30%
          float: right
          margin-bottom: 20px
          margin-right: 20px
          z-index: 100

  @media (min-width: $lg)
    .additional-content
      .content_block
        .content-block-text
          .content_block_content
            margin-left: 60px
      .slick-arrow
        top: 50%
        width: 40px
        height: 40px
        background-size: 40px

      .slick-list
        margin: 0 -27px
        .button-prev
          left: 35px
        .button-next
          right: 35px
        .slick-slide
          margin: 0 27px
  @media (min-width: $xl)
    h1:first-child
      width: 60%
    .additional-content
      margin-top: -10%
      .content_block
        .content-block-text
          .content_block_content
            margin-top: 20%
            margin-left: 60px
            *
              font-size: 25px
        .content_block_image
          img
            max-width: 600px
