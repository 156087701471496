@keyframes pulse-shade-conlance-green
  0%
    box-shadow: 0 0 0 0 rgba($conlance-green,.7)
    -webkit-box-shadow: 0 0 0 0 rgba($conlance-green,.7)
    -moz-box-shadow: 0 0 0 0 rgba($conlance-green,.7)
  100%
    box-shadow: 0 0 0 15px rgba($conlance-green,0)
    -webkit-box-shadow: 0 0 0 15px rgba($conlance-green,0)
    -moz-box-shadow: 0 0 0 15px rgba($conlance-green,0)

@keyframes pulse-shade-white
  0%
    box-shadow: 0 0 0 0 rgba(white,.7)
    -webkit-box-shadow: 0 0 0 0 rgba(white,.7)
    -moz-box-shadow: 0 0 0 0 rgba(white,.7)
  100%
    box-shadow: 0 0 0 15px rgba(white,0)
    -webkit-box-shadow: 0 0 0 15px rgba(white,0)
    -moz-box-shadow: 0 0 0 15px rgba(white,0)

.cutout-card
  background-color: $navbar-subsection-items-color
  border-radius: 0
  border-style: none
  margin: 0
  .card-body
    color: white
    .card-text
      margin: 0
      i, p
        font-family: $system-font
        font-size: 1.2rem
        line-height: 1.2
        color: white
        margin: 0
  .btn-inline-cta
    margin: .5rem
    line-height: 1.2
    font-size: 1.2rem
    text-decoration: none
    border-radius: 0
    border-style: solid
    border-width: .12rem
    border-color: white
    background-color: $navbar-subsection-items-color
    color: white
    transition: all .5s ease-in-out
    animation: pulse-shade-white 2.5s infinite
    &:hover, &:focus
      background-color: lighten($navbar-subsection-items-color, 3%)
      transform: scale(1.02)
      animation: none
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)
      -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)
      -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)
  .inline-phone-contact
    color: white
    a
      color: white
      &:hover, &:focus
        color: white

.cta-separator-overviews
  margin-top: 1rem
  margin-bottom: 3rem

.cta-separator-benefits
  margin-top: 2rem
  margin-bottom: 3rem

.phone-contact
  text-align: center
  font-size: 1rem

  bold
    font-size: 1.25rem
    color: $conlance-green !important

.call-to-action
  font-family: $system-font

  .contact-submit-btn
    color: white
    display: block
    width: 100%
    background-color: $conlance-green
    font-size: 1rem
    padding-left: 1px
    padding-right: 1px
    border-radius: 0px
    border-style: none
    transition: all .5s ease-in-out
    animation: pulse-shade-conlance-green 2.5s infinite

    &:hover, &:focus
      transform: scale(1.02)
      cursor: pointer
      background-color: lighten($conlance-green, 3%) !important
      animation: none
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)
      -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)
      -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)

@media (max-width: $md)
  .phone-contact
    bold
      font-size: .9rem

  .cutout-card
    .card-body
      .card-text
        i
          font-size: 1rem
    .btn-inline-cta
      font-size: 1rem

@media (min-width: $sm)
  .call-to-action
    .contact-submit-btn
      font-size: 1.4rem

@media (min-width: $lg)
  .call-to-action
    .contact-submit-btn
      font-size: 1.5rem
