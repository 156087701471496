//---------------------------------margins--------------------------------------
//---------------------------------margin---------------------------------------
.m-1
  margin: 0.5rem
.m-2
  margin: 1rem
.m-3
  margin: 2rem
.m-4
  margin: 3rem
.m-5
  margin: 5rem
//--------------------------margin y direction----------------------------------
.my-1
  margin-top: 0.5rem
  margin-bottom: 0.5rem
.my-2
  margin-top: 1rem
  margin-bottom: 1rem
.my-3
  margin-top: 2rem
  margin-bottom: 2rem
.my-4
  margin-top: 3rem
  margin-bottom: 3rem
.my-5
  margin-top: 5rem
  margin-bottom: 5rem
.my-6
  margin-top: 8rem
  margin-bottom: 8rem

@media screen and (min-width: $md)
  .my-md-5
    margin-top: 5rem
    margin-bottom: 5rem
  .my-md-6
    margin-top: 8rem
    margin-bottom: 8rem

@media screen and (min-width: $lg)
  .my-lg-5
    margin-top: 5rem
    margin-bottom: 5rem
  .my-lg-6
    margin-top: 8rem
    margin-bottom: 8rem

.new-section
  @media screen and (min-width: $xs)
    margin-bottom: 0
  @media screen and (min-width: $lg)
    margin-bottom: 32.5vw
  @media screen and (min-width: $xl)
    margin-bottom: 25vw
  @media screen and (min-width: 1400px)
    margin-bottom: 350px
//--------------------------margin x direction----------------------------------
.mx-1
  margin-left: 0.5rem
  margin-right: 0.5rem
.mx-2
  margin-left: 1rem
  margin-right: 1rem
.mx-3
  margin-left: 2rem
  margin-right: 2rem
.mx-4
  margin-left: 3rem
  margin-right: 3rem
.mx-5
  margin-left: 5rem
  margin-right: 5rem
  //----------------------margin bottom direction-------------------------------
  .mb-1
    margin-bottom: 0.5rem
  .mb-2
    margin-bottom: 1rem
  .mb-3
    margin-bottom: 2rem
  .mb-4
    margin-bottom: 3rem
  .mb-5
    margin-bottom: 5rem
