$animationSpeed10: 90s
$animationSpeed11: 99s


@keyframes scroll11
  0%
    transform: translateX(0)
  100%
    transform: translateX(calc(-250px * 11))
@keyframes scroll10
  0%
    transform: translateX(0)
  100%
    transform: translateX(calc(-250px * 10))

.customerLogosRowWith10Customers
  .customer-logos-slider
    overflow: hidden
    .customer-logos-row
      animation: scroll10 $animationSpeed10 linear infinite
      display: flex
      width: calc(250px * 20)
      margin: 24px 0
    .customer-logo-container
      height: 100px
      width: 250px
      transition: transform 1s
      &:hover
        transform: scale(1.05)
      img
        filter: grayscale(100%)
        max-width: 160px
        max-height: 80px

.customerLogosRowWith11Customers
  .customer-logos-slider
    overflow: hidden
    .customer-logos-row
      animation: scroll11 $animationSpeed11 linear infinite
      display: flex
      width: calc(250px * 22)
      margin: 24px 0
    .customer-logo-container
      height: 100px
      width: 250px
      transition: transform 1s
      &:hover
        transform: scale(1.05)
      img
        filter: grayscale(100%)
        max-width: 160px
        max-height: 80px
