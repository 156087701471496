#navbar
  .white-font
    color: white

  z-index: 1030
  padding: 0 2px
  background-color: $conlance-green
  position: fixed
  display: flex
  flex-wrap: nowrap
  width: 100vw
  min-width: $navbar-size-mobile
  height: $navbar-height
  line-height: 1
  top: 0
  box-shadow: 0 8px 9px -7px rgba(0,0,0,0.2)
  -webkit-box-shadow: 0 8px 9px -7px rgba(0,0,0,0.2)
  -moz-box-shadow: 0 8px 9px -7px rgba(0,0,0,0.2)

  .navbar-logo
    text-align: center
    padding-left: $page-padding-xs

    a
      font-size: $navbar-top-text-size
      color: $navbar-text-color

      &:hover
        color: $navbar-text-color
        text-decoration: none

    img
      display: block
      width: 3.2rem
      margin: 0 auto

  .navbar-nav
    flex-direction: row
    padding-right: $page-padding-xs

    .nav-item
      text-align: center
      margin-left: $spacer

      a
        text-decoration: none
        font-family: $system-font
        font-weight: 600
        color: $navbar-text-color

        img
          margin: auto
          display: block
          width: 40px
          height: 40px

        &:hover
          color: $navbar-text-color
          text-decoration: none

      p
        font-weight: 600
        font-size: $navbar-top-text-size
        color: white
        margin-top: 4px

  .dropdown
    .dropdown-toggle:after
      display: none

    .dropdown-menu
      position: absolute
      padding-top: 2.5rem
      left: -10px
      top: 1rem
      color: $navbar-text-color
      background-color: transparent
      border: none

      a, a:hover
        display: table
        width: auto
        font-size: 15px
        padding: .5em
        margin-bottom: 1em
        margin-left: 10px
        box-shadow: 5px 5px 9px -5px rgba(0,0,0,0.2)
        -webkit-box-shadow: 5px 5px 9px -5px rgba(0,0,0,0.2)
        -moz-box-shadow: 5px 5px 9px -5px rgba(0,0,0,0.2)

      a
        background-color: $conlance-green

        &:hover
          background-color: $conlance-light-grey

  #more-collapse
    border-radius: 0

    ul
      height: auto
      max-height: 90%
      overflow: auto
      display: flex
      flex-direction: row-reverse
      list-style: none
      position: fixed
      padding-left: 0
      right: 0
      padding-top: 1.5rem
      padding-bottom: 1.5rem
      top: $navbar-height
      background-color: #82b500
      margin-top: 0
      width: 100%
      box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2)
      -webkit-box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2)
      -moz-box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2)
      font-weight: 600
      color: #fff

    .nested-ul
      list-style: none
      padding: 0
      margin-left: 2rem
      .heading
        font-family: $system-font
        color: #fff
        cursor: pointer

        span
          margin-bottom: .5rem
          padding: .5rem
          display: inline-block

        a
          margin: 0
          font-weight: 600

      a
        margin: 0

      .submenu-links-container
        @media (max-width: $ml)
          background-color: $navbar-subsection-subitems-color
          padding: 5px
          box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2), inset 0 -7px 9px -7px rgba(0,0,0,0.2)
          -webkit-box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2), inset 0 -7px 9px -7px rgba(0,0,0,0.2)
          -moz-box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2), inset 0 -7px 9px -7px rgba(0,0,0,0.2)
          a
            background-color: $navbar-subsection-subitems-color

#navbar
  .mobile-nav
    display: block
  @media (min-width: $sm)
    ul a, .more-nav .heading a, .more-nav .heading span, .heading
      font-size: $navbar-top-md-text-size !important

    #more-collapse
      ul.more-nav
        width: 100%
        background-color: $navbar-subsection-items-color

  @media (min-width: $ml)
    .mobile-nav
      display: none
    .nav-item:hover
      .dropdown-menu
        display: block

    .dropdown
      .dropdown-toggle:active
        pointer-events: none

    ul a, .more-nav .heading a, .more-nav .heading span
      font-size: $navbar-top-md-text-size !important

  @media (min-width: $lg)
    ul a, .more-nav .heading a, .more-nav .heading span
      font-size: $navbar-top-text-size !important

  @media (min-width: 1300px)
    .navbar-logo, .more-nav
      padding-left: $page-padding-xl

    ul.navbar-nav, .more-nav
      padding-right: $page-padding-xl

    ul a, .more-nav .heading a, .more-nav .heading span
      font-size: $navbar-top-text-size !important

    .navbar-nav
      .nav-item
        margin-left: $spacer * 1.5

  @media (max-width: $ml)
    ul.more-nav
      text-align: right
      flex-direction: column !important
      margin-left: 0

      li a
        display: block !important

    .nested-ul
      margin-left: 0 !important

    li.nav-item.desktop
      display: none

  @media (max-width: $sm)
    ul.more-nav
      .submenu-link, li span a, .heading span
        padding-left: $page-padding-xs !important
        padding-right: $page-padding-xs !important
  @media (max-width: $xs)
    ul.more-nav
      .submenu-link, li span a, .heading span
        padding-left: $page-padding-xs !important
        padding-right: $page-padding-xs !important
