a
  color: $conlance-green
a:hover
  color: $conlance-green

p.contact
  margin-top: 0
  margin-bottom: 0rem
  font-size: medium

.col-lg-6.name, .col-lg-6.subject
  margin-bottom: 10px

.fillin-hint
  font-size: small
  color: $conlance-light-grey

.contact-submit-btn
  background-color: $conlance-green
  color: white !important
  display: inline
  padding: 10px 40px
  width: auto
  border-radius: 0px
  border-style: none

.contact-submit-btn:hover
  background-color: $conlance-light-grey !important

.button
  color: white !important
  background-color: $conlance-green
  border: none
  border-radius: 0px
  float: right
  padding: 0.5%
  width: 250px
  padding-left: 55px

.button:hover
 color: white !important
 background-color: $conlance-light-grey !important
