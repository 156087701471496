.no-webp, .no-js
  .bg-meeting-room
    background: url(../images/backgrounds/jpeg/besprechungsraum.jpg) no-repeat (center / cover)

  .bg-meeting-room-2
    background: url(../images/backgrounds/jpeg/meeting_room.jpg) no-repeat (center / cover)

  .bg-meeting-room-detail
    background: url(../images/backgrounds/jpeg/meeting_room_detail.jpg) no-repeat (center / cover)

  .bg-augsburg
    background: url(../images/backgrounds/jpeg/augsburg.jpg) no-repeat (center / cover)

  .bg-whiteboard
    background: url(../images/backgrounds/jpeg/whiteboard.jpg) no-repeat (center / cover)

  .bg-postits
    background: url(../images/backgrounds/jpeg/postits.jpg) no-repeat (center / cover)

  .bg-kanban
    background: url(../images/backgrounds/jpeg/kanban.jpg) no-repeat (center / cover)

  .bg-workplace-1
    background: url(../images/backgrounds/jpeg/workplace_1.jpg) no-repeat (center / cover)

  .bg-workplace-2
    background: url(../images/backgrounds/jpeg/workplace_2.jpg) no-repeat (center / cover)

  .bg-happy-people
    background: url(../images/backgrounds/jpeg/happy_people.jpg) no-repeat (center / cover)

  .bg-face-to-face
    background: url(../images/backgrounds/jpeg/face_to_face.jpg) no-repeat (center / cover)

  .bg-files
    background: url(../images/backgrounds/jpeg/files.jpg) no-repeat (center / cover)

  .bg-server
    background: url(../images/backgrounds/jpeg/server.jpg) no-repeat (center / cover)

  .bg-people-phones
    background: url(../images/backgrounds/jpeg/people_phones.jpg) no-repeat (center / cover)

  .bg-router
    background: url(../images/backgrounds/jpeg/router.jpg) no-repeat (center / cover)

  .bg-access-point
    background: url(../images/backgrounds/jpeg/access_point.jpg) no-repeat (center / cover)

  .bg-texting
    background: url(../images/backgrounds/jpeg/texting.jpg) no-repeat (center / cover)

  .bg-phone-code
    background: url(../images/backgrounds/jpeg/phone_code.jpg) no-repeat (center / cover)

  .bg-user-centered
    background: url(../images/backgrounds/jpeg/user_centered.jpg) no-repeat (center / cover)

  .bg-web-dev-meeting
    background: url(../images/backgrounds/jpeg/web_dev_meeting.jpg) no-repeat (center / cover)

  .bg-design-sketch
    background: url(../images/backgrounds/jpeg/design_sketch.jpg) no-repeat (center / cover)

  .bg-web-work
    background: url(../images/backgrounds/jpeg/web_work.jpg) no-repeat (center / cover)

  .bg-office
    background: url(../images/backgrounds/jpeg/office.jpg) no-repeat (center / cover)

  .bg-office-worker
    background: url(../images/backgrounds/jpeg/office_worker.jpg) no-repeat (center / cover)

  .bg-busy-office
    background: url(../images/backgrounds/jpeg/busy_office.jpg) no-repeat (center / cover)

  .bg-wallet
    background: url(../images/backgrounds/jpeg/wallet.jpg) no-repeat (center / cover)

  .bg-shop-counter
    background: url(../images/backgrounds/jpeg/shop_counter.jpg) no-repeat (center / cover)

  .bg-notebook-typing
    background: url(../images/backgrounds/jpeg/notebook_typing.jpg) no-repeat (center / cover)

  .bg-turnstiles
    background: url(../images/backgrounds/jpeg/turnstiles.jpg) no-repeat (center / cover)

  .bg-tablet-terminal
    background: url(../images/backgrounds/jpeg/tablet_terminal.jpg) no-repeat (center / cover)

  .bg-access-mobile-1
    background: url(../images/backgrounds/jpeg/access_mobile_1.jpg) no-repeat (center / cover)

  .bg-access-mobile-2
    background: url(../images/backgrounds/jpeg/access_mobile_2.jpg) no-repeat (center / cover)

  .bg-uxi-whiteboard
    background: url(../images/backgrounds/jpeg/uxi_whiteboard.jpg) no-repeat (center / cover)

  .bg-laptop-red
    background: url(../images/backgrounds/jpeg/laptop_red.jpg) no-repeat (center / cover)

  .bg-laptop-red-2
    background: url(../images/backgrounds/jpeg/laptop_red_2.jpg) no-repeat (center / cover)

  .bg-laptop-rb-file
    background: url(../images/backgrounds/jpeg/laptop_rb_file.jpg) no-repeat (center / cover)

  .bg-rails-spec
    background: url(../images/backgrounds/jpeg/rails_spec.jpg) no-repeat (center / cover)

  .bg-harry-back
    background: url(../images/backgrounds/jpeg/harry_back.jpg) no-repeat (center / cover)

  .bg-blurry-editor
    background: url(../images/backgrounds/jpeg/blurry_editor.jpg) no-repeat (center / cover)

  .bg-blurry-editor-2
    background: url(../images/backgrounds/jpeg/blurry_editor_2.jpg) no-repeat (center / cover)

  .bg-all-apple
    background: url(../images/backgrounds/jpeg/all_apple.jpg) no-repeat (center / cover)

  .bg-iphone-mac
    background: url(../images/backgrounds/jpeg/iphone_mac.jpg) no-repeat (center / cover)

  .bg-apple-light-devices
    background: url(../images/backgrounds/jpeg/apple_light_devices.jpg) no-repeat (center / cover)

  .bg-versatile-ios
    background: url(../images/backgrounds/jpeg/versatile_ios.jpg) no-repeat (center / cover)

  .bg-big-screen
    background: url(../images/backgrounds/jpeg/big_screen.jpg) no-repeat (center / cover)

  .bg-android-developer
    background: url(../images/backgrounds/jpeg/android_developer.jpg) no-repeat (center / cover)

  .bg-android-on-keyboard
    background: url(../images/backgrounds/jpeg/android_on_keyboard.jpg) no-repeat (center / cover)

  .bg-samsung-phone
    background: url(../images/backgrounds/jpeg/samsung_phone.jpg) no-repeat (center / cover)

  .bg-iphone-android
    background: url(../images/backgrounds/jpeg/iphone_android.jpg) no-repeat (center / cover)

  .bg-javascript-reflection
    background: url(../images/backgrounds/jpeg/javascript_reflection.jpg) no-repeat (center / cover)

  .bg-long-javascript
    background: url(../images/backgrounds/jpeg/long_javascript.jpg) no-repeat (center / cover)

  .bg-react-closeup
    background: url(../images/backgrounds/jpeg/react_closeup.jpg) no-repeat (center / cover)

  .bg-paper-archive
    background: url(../images/backgrounds/jpeg/paper_archive.jpg) no-repeat (center / cover)

  .bg-engineer-working
    background: url(../images/backgrounds/jpeg/engineer_working.jpg) no-repeat (center / cover)

  .bg-sql-snippet
    background: url(../images/backgrounds/jpeg/sql_snippet.jpg) no-repeat (center / cover)

  .bg-rails-model
    background: url(../images/backgrounds/jpeg/rails_model.jpg) no-repeat (center / cover)

  .bg-router-backside
    background: url(../images/backgrounds/jpeg/router_backside.jpg) no-repeat (center / cover)

  .bg-html-css-side-by-side
    background: url(../images/backgrounds/jpeg/html_css_side_by_side.jpg) no-repeat (center / cover)

  .bg-css-closeup
    background: url(../images/backgrounds/jpeg/css_closeup.jpg) no-repeat (center / cover)

  .bg-web-inspector
    background: url(../images/backgrounds/jpeg/web_inspector.jpg) no-repeat (center / cover)

  .bg-wordpress-meeting
    background: url(../images/backgrounds/jpeg/wordpress-meeting.jpg) no-repeat (center / cover) 

  .bg-wordpress-typing
    background: url(../images/backgrounds/jpeg/wordpress-typing.jpg) no-repeat (center / cover)

  .bg-html-closeup
    background: url(../images/backgrounds/jpeg/html_closeup.jpg) no-repeat (center / cover)

  .bg-php-closeup
    background: url(../images/backgrounds/jpeg/php_closeup.jpg) no-repeat (center / cover)

  .bg-php-wordpress-code
    // background: url(../images/backgrounds/jpeg/php_wordpess_code.jpg) no-repeat (center / cover)

  .bg-coding-wordpress    
    background: url(../images/backgrounds/jpeg/coding-wordpress.jpg) no-repeat (center / cover)


