.mw-100
  max-width: 100%

.w-80
  width: 80%

.disable-cursor-change
  cursor: default

.font-to-montserrat
  font-family: $system-font

body
  background-color: $background-color
  overflow-x: hidden !important

body.pages
  overflow-x: hidden !important

p, li, cite
  font-family: $system-font

.full-width
  width: 100%

@media (min-width: $xs)
  h1
    font-size: 2rem
  h2
    font-size: 1.25rem
  h3
    font-size: 1.25rem
  p
    font-size: 1rem

  body
    font-family: $system-font
    font-weight: normal
    min-height: 100%

  body.pages
    background-color: $background-color
    p, h1, h3, h4, h5, h6, li, div
      color: $main-text-color
      --sal-duration: 1s
      p, li
        -webkit-hyphens: auto
        -ms-hyphens: auto
        hyphens: auto
    a, a:hover
      color: $conlance-green

  a, a:hover
    text-decoration: none !important

  main
    min-height: 100vh

  .page-messages
    padding: 0 !important
    position: fixed !important
    top: 25px
    z-index: 1000
    .alert
      margin: $navbar-size-mobile
    button:focus
      outline: 0

  .page-content
    margin-top: $navbar-height
    margin-bottom: 50px

  .head-image
    background-color: #5b5d62
  .gym10-reference-bg
    background-color: #0B3454

  .html-content a:hover
    color: $conlance-light-grey !important

  .green_link
    color: $conlance-green !important

  .separator-page-headline
    margin-top: 150px

  .separator-page-image
    margin-top: 80px

  .separator-paragraph
    margin-bottom: 3rem

  .separator-sub-paragraph
    margin-bottom: 2rem

  .separator-section
    margin-bottom: 6rem

  .content-divider
    margin-bottom: 75px

@media (min-width: $md)
  main
    .page-messages
      .alert
        margin-left: 0

@media (min-width: $lg)
  h1
    font-size: 2.25rem
  h2
    font-size: 1.5rem
  h3
    font-size: 1.5rem
  p, li, cite
    font-size: 1rem

  .separator-lg-section
    margin-bottom: 6rem

@media (min-width: $xl)
  h1
    font-size: 2.5rem
  h2
    font-size: 1.5rem
  h3
    font-size: 1.5rem
  p, li, cite
    font-size: 1.1rem

@media (min-width: 1500px)
  .col-xxl-3
    -webkit-box-flex: 0
    flex: 0 0 25%
    max-width: 25%

  .col-xxl-10
    -webkit-box-flex: 0
    flex: 0 0 83.3333333333%
    max-width: 83.3333333333%

@media (min-width: 1800px)
  .col-xxxl-8
    -webkit-box-flex: 0
    flex: 0 0 66.6666666667%
    max-width: 66.6666666667%

  .col-xxxl-9
    -webkit-box-flex: 0
    flex: 0 0 75%
    max-width: 75%
