.image_carousel
  @media (min-width: $xs)
    .page-part
      overflow: hidden
      padding: 0

    .additional-content
      position: relative

      .button-prev
        background-image: url(../images/image-slider/arrow-left.svg)
        left: 10px
        &:hover
          background-image: url(../images/image-slider/arrow-left-hover.svg)

      .button-next
        background-image: url(../images/image-slider/arrow-right.svg)
        right: 10px
        &:hover
          background-image: url(../images/image-slider/arrow-right-hover.svg)

      .button-prev, .button-next, .slick-dots
        cursor: pointer

      .slick-dots
        position: absolute
        left: 50%
        transform: translateX(-50%)
        margin-top: -20px
        margin-bottom: 0
        padding-left: 0
        li[role=presentation]
          margin-left: 5px
          border: solid 2px $conlance-green
          border-radius: 5px
          display: inline-block
          height: 10px
          width: 10px
          &.slick-active
            background-color: $conlance-green
          button
            display: none

      .slick-list
        // we don't have the reviewtexts in the database
        // to get the longest text and size the slick-list according to it
        // I hardcoded the minimum height of the longest text as of 2019-11-15
        min-height: 785px !important
        margin-left: - $page-padding-xs
        margin-right: - $page-padding-xs
        overflow: hidden
        padding-bottom: -45px

      .content_block
        display: flex !important
        align-items: center
        padding: 0 10%
        .content_block_image
          width: auto
          margin-bottom: 20px
          display: block
          img
            object-fit: cover
            width: 100%
            margin-top: 35px

  @media (min-width: $sm)
    .additional-content
      .content_block
        padding: 0 5%
      .slick-list
        // we don't have the reviewtexts in the database
        // to get the longest text and size the slick-list according to it
        // I hardcoded the minimum height of the longest text as of 2019-11-15
        min-height: 350px !important
        margin-left: 0
        margin-right: 0
        .button-prev
          left: 20px
        .button-next
          right: 20px

  @media (min-width: $md)
    .additional-content
      padding: 0% 10% 0% 10%

      .slick-arrow
        display: inline
        width: 30px
        height: 30px
        background-size: 30px
        top: 50%
        z-index: 1000
        transform: translateY(-100%)
        position: absolute

  @media (min-width: $lg)
    .additional-content
      .slick-arrow
        width: 40px
        height: 40px
        background-size: 40px

      .slick-list
        // we don't have the reviewtexts in the database
        // to get the longest text and size the slick-list according to it
        // I hardcoded the minimum height of the longest text as of 2019-11-15
        min-height: 400px !important
        margin: 0 -27px
        .button-prev
          left: 35px
        .button-next
          right: 35px
        .slick-slide
          margin: 0 27px

      .slick-dots
        margin-top: 0
