#footer
  @media (min-width: $xs)
      bottom: 0
      font-size: $navbar-text-size
      display: flex
      align-items: center
      justify-content: space-between
      height: auto
      background-color: $footer-color

      padding: 1% 1% 1% 2%
      line-height: 2
      .flex-direction-rr
        flex-direction: row-reverse
      .flex-direction-r
        flex-direction: row
      .set-flex-basis
        flex-basis: auto
      .nav
        display: flex
        align-items: center
        white-space: nowrap
        .nav-link
          float: right
          padding: 0 5px
          color: $navbar-text-color
        a
          color: $navbar-text-color
          cursor: pointer
          padding-left: 5px
        a:hover
          color: $conlance-green
        .separator
          display: none
  @media (min-width: $sm)
    .nav
      .separator
        display: inline-block
      a
        padding-left: unset
  @media (min-width: $md)
    min-height: $navbar-size-desktop

    .set-flex-basis
      flex-basis: 100%
  @media (min-width: $lg)
    font-size: $footer-text-size-m
    min-height: $navbar-size-desktop
    padding-right: 2%
  @media (min-width: $xl)
    font-size: $footer-text-size-lg
  @media (min-width: 2000px)
    font-size: $footer-text-size-xl
