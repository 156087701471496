#conlanceKlaro
  .klaro
    .cookie-modal, .cookie-notice
      font-size: 14px

    .cm-list-input:checked + .cm-list-label .slider, .only-required + .cm-list-label .slider
      background-color: $conlance-green

    .cookie-modal .slider, .cookie-notice .slider
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 0 0 rgba(255, 255, 255, 0)

    .cookie-modal a, .cookie-notice a
      color: $conlance-green

    .cookie-modal .cm-btn, .cookie-notice .cm-btn
      background: $conlance-green
      border-radius: 0
      padding: 6px 10px
      margin-right: 0
      margin-top: 0
      border: 0

    .cn-buttons
      margin: 0

    .cookie-modal
      width: 100%
      height: 100%
      position: fixed
      overflow: hidden
      left: 0
      top: 0
      z-index: 2000

      .cm-bg
        background: rgba(0, 0, 0, 0.5)
        height: 100%
        width: 100%
        position: fixed
        top: 0
        left: 0

      .cm-modal
        z-index: 2000
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19)
        max-height: 98%
        max-width: 100%
        top: 50%
        transform: translateY(-50%)
        position: fixed
        overflow: auto
        background: white
        color: $main-text-color
        display: flex
        flex-direction: column

        .hide
          border: none
          background: none
          position: absolute
          top: 20px
          right: 20px
          z-index: 1

          svg
            stroke: #ccc

        .cm-footer
          padding: 1em
          border-top: 1px solid #ccc

        .cm-footer-buttons
          flex-direction: column-reverse
          margin-bottom: 0
          display: flex
          justify-content: space-evenly
          font-size: 1.5em
          &::before
            content: " "
            display: table

          &::after
            content: " "
            display: table
            clear: both

        .cm-powered-by
          display: none

        .cm-footer
          font-size: 0.8em
          padding-top: 4px
          text-align: center
          .cm-btn-accept-all, .cn-decline
            margin: 1em 0
            background-color: $conlance-green
            color: white
            &:hover, &:focus
              background-color: lighten($conlance-green, 3%)
          .cm-btn-info
            margin: 1em 0
            border-color: $conlance-green
            &:hover, &:focus
              background-color: lighten($conlance-green, 3%)

        .cm-header
          padding: 1em
          padding-right: 24px
          border-bottom: 1px solid #ccc

          h1, p
            color: $main-text-color

          h1
            margin: 0
            font-size: 2em
            display: block

            &.title
              padding-right: 20px

        .cm-body
          padding: 1em

          ul.cm-services li.cm-service
            p
              color: $main-text-color

              &.purposes
                font-size: 0.8em
                color: #999

            &.cm-toggle-all
              border-top: 1px solid #ccc
              padding-top: 1em

            span
              &.cm-list-title
                font-weight: 600
                margin-top: 0.3rem

              &.cm-opt-out, &.cm-required
                padding-left: 0.2em
                font-size: 0.8em
                color: #999

    .cookie-notice
      display: none

      .cn-body
        background: white
        padding: 1rem
        max-width: 500px
        height: 270px
        margin-top: 100px
        display: flex
        flex-direction: column
        justify-content: space-evenly

        p
          margin-top: 0
          margin-bottom: 0.5em
          color: $main-text-color

        strong
          color: $main-text-color

          &.cn-changes
            text-decoration: underline

        .cn-learn-more
          display: inline-block

        .cn-ok
          padding-top: 0.5em
          margin: 0
          display: flex
          flex-direction: column-reverse
          align-items: center
          .cn-buttons
            width: 100%
            .cm-btn-success
              margin-bottom: 1em
              color: white
              background-color: $conlance-green
              width: 100%
              font-size: 1.2rem
              &:hover, &:focus
                background-color: lighten($conlance-green, 3%)

    .cookie-notice-hidden
      display: none !important

  @media (min-width: 1024px)
    .klaro .cookie-modal .cm-modal
      border-radius: 0
      position: relative
      margin: 0 auto
      max-width: 640px
      height: auto
      width: auto

  @media (min-width: 990px)
    .klaro .cookie-notice
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19)
      border-radius: 0
      bottom: 0
      right: 0

    .cm-modal
      padding: 1rem

    .cm-footer-buttons
      .cm-btn
        min-width: 281px

  @media (min-width: 660px)
    .klaro .cookie-modal .cm-modal
      border-radius: 0

  @media (max-width: 989px)
    .klaro .cookie-notice
      border: none
      border-radius: 0

  @media (max-width: $sm)
    .cm-footer-buttons
      flex-direction: column-reverse
