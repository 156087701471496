@import bg_lists/bg_jpeg
@import bg_lists/bg_webp

.webp, .no-webp
  .parallax
    height: 500px
    background-attachment: fixed
    display: flex
    justify-content: center
    align-items: center

    background-size: auto 100vh
    -webkit-background-size: auto 100vh
    -moz-background-size: auto 100vh
    -o-background-size: auto 100vh

    background-position: center top

  @media (min-width: $md)

    .parallax
      background-size: cover
      -webkit-background-size: cover
      -moz-background-size: cover
      -o-background-size: cover

      background-position: center
