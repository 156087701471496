.home
  h2
    font-family: $system-font
    color: $conlance-green
    font-weight: 700
  p
    line-height: 1.7
    letter-spacing: .5px

.service-img
  max-width: 80%
  transform: scale(0.9)
  display: block

.tech-img
  max-width: 70%
  display: block

.shadow-card
  border-radius: 0
  border-style: none
  margin-bottom: $spacer
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)
  -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19)
  .card-body
    line-height: 1.8
    letter-spacing: .25px
  .card-footer
    border-radius: inherit
    border-style: inherit
    background-color: inherit
    padding: 0
    text-align: center
    .btn-primary
      text-decoration: none
      border-radius: 0
      border-style: none
      font-size: 1.3em
      width: 100%
      background-color: $conlance-green
      color: white
      &:hover, &:focus
        background-color: lighten($conlance-green, 3%)

.linking-card
  transition: all .5s ease-in-out
  &:hover, &:focus
    transform: scale(1.02)

.counter
  font-size: 28px
  margin: 5px 0

// small portrait phones
@media (max-width: 370px)
  .home
    .break-word
      word-wrap: break-word

@include media-breakpoint-down(md)

  .large-card
    .card-body
      min-height: 224px

  .small-card
    .card-body
      min-height: 220px

@include media-breakpoint-up(sm)
  .mx-tech
    margin-left: $spacer
    margin-right: $spacer

  .large-card
    .card-body
      min-height: 244px

  .small-card
    .card-body
      min-height: 248px

@include media-breakpoint-up(md)
  .mx-tech
    margin-left: $spacer * 2
    margin-right: $spacer * 2

  .large-card
    .card-body
      min-height: 181px

@include media-breakpoint-up(lg)
  .service-img
    max-width: 100%

  .large-card
    .card-body
      min-height: 248px

@media (min-width: 1500px)
  .tech-img
    max-width: 100%

  .large-card
    .card-body
      min-height: 248px

  .small-card
    .card-body
      min-height: 200px

@media (min-width: 1700px)
  .container-tech
    max-width: 1250px
